// VenuePage.jsx
import React from 'react';
// import PaymentForm from './PaymentForm';


const VenuePage = () => {
  return (
    <div>
      <h2>Venue Page</h2>
      {/* Your VenuePage content */}
 
    </div>
  );
};

export default VenuePage;

